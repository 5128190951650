import * as S from "./styles";
import { StepProps } from "../steps";
import { useTranslation } from "react-i18next";
import { GENERATION_PREFERENCES } from "./constants";
import FlexContainer from "~/components/ui/FlexContainer";

export const SelectPreference = ({
  onboardingChoices,
  setOnboardingChoices,
}: StepProps) => {
  const { t } = useTranslation();

  const newPreferences = GENERATION_PREFERENCES
    ? [...GENERATION_PREFERENCES, { name: t("onboarding.all"), value: "all" }]
    : [];

  const areAllPreferencesSelected = () => {
    return (
      onboardingChoices?.generation_preferences?.length ===
        GENERATION_PREFERENCES.length &&
      onboardingChoices?.generation_preferences?.every((catValue: string) =>
        GENERATION_PREFERENCES.some((category) => category.value === catValue)
      )
    );
  };

  const handleAllSelection = () => {
    if (areAllPreferencesSelected()) {
      setOnboardingChoices((old) => ({
        marketplaces: old?.marketplaces || [],
        generation_preferences: [],
      }));
    } else {
      setOnboardingChoices((old) => ({
        marketplaces: old?.marketplaces || [],
        generation_preferences: GENERATION_PREFERENCES.map(
          (category) => category.value
        ),
      }));
    }
  };

  const handlePreferenceSelection = (value: string) => {
    setOnboardingChoices((old) => {
      let prefs = [];
      if (old?.generation_preferences?.includes(value)) {
        prefs = old.generation_preferences.filter(
          (catValue) => catValue !== value
        );
      } else {
        const newPreferences = old?.generation_preferences?.includes(null as any)
          ? []
          : [...(old?.generation_preferences || [])];
        prefs = [...newPreferences, value];
      }
      return {
        marketplaces: old?.marketplaces || [],
        generation_preferences: prefs,
      };
    });
  };

  const handlePreferenceSelect = (catValue: string) => {
    if (catValue === "all") {
      handleAllSelection();
    } else {
      handlePreferenceSelection(catValue);
    }
  };

  return (
    <FlexContainer gap="16px" flexWrap="wrap" width="100%">
      {newPreferences?.map((category) => (
        <S.CardPreference
          key={category.value}
          className={
            onboardingChoices?.generation_preferences?.includes(category.value)
              ? "active"
              : ""
          }
          onClick={() => handlePreferenceSelect(category.value)}>
          {category.name}
        </S.CardPreference>
      ))}
    </FlexContainer>
  );
};
