import { IPlan } from "./billing";

export interface ISettings {
  description_placement: string;
  hide_placement_reminder: boolean;
}

export enum PlanStatus {
  ACTIVE = "AC",
  PAUSED = "PA",
  INACTIVE = "IN",
  PAST_DUE = "PD",
}

export interface IActiveSubscription {
  id: string;
  payment_provider: string;
  plan: IPlan;
  status: PlanStatus;
  created_at: string;
  updated_at: string;
  paused_at: string;
  cancelled_at: string;
  cancel_at: string;
  trial_end_at: string;
  customer_id: string;
  plan_id: string;
  uid: string;
}

export interface IAccount {
  ads_writer: boolean;
  blog_generation_count: number;
  description_generation_count: number;
  email: string;
  has_password: boolean;
  id: string;
  name: string;
  created_at: string;
  stripe_card_digits: string;
  payment_provider: string;
  signup_origin: string;
  active_subscription: IActiveSubscription;
  has_subscribed_before: boolean;
  is_annual_shopify: boolean;
  last_subscription?: IActiveSubscription;
  pause_count: number;
  onboarding_choices?: IOnboardingChoices;
  has_created_stripe_upgrade_funnel_coupon: boolean;
  has_used_stripe_upgrade_funnel_coupon: boolean;
}

export interface ICurrentPlan {
  plan: IPlan;
  subscription: IActiveSubscription;
  user_id: string;
}

export interface IOnboardingChoices {
  generation_preferences: string[];
  marketplaces: string[];
}

export interface IUserData {
  account: IAccount;
  currentPlan: ICurrentPlan;
  isLoading: boolean;
  refreshAccount: () => void;
}


