import styled from "styled-components";
import { getColor, getSize } from "~/helpers/style";

export const StepTitle = styled.h2`
  color: ${getColor("text")};
  font-size: ${getSize(1.6)};
  font-weight: 600;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > svg {
    width: ${getSize(2)};
    height: ${getSize(2)};
  }

  & > svg > circle {
    stroke: ${getColor("white")};
  }
`;
