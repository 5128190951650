import * as S from "./styles";
import { StepProps } from "../steps";
import { MARKETPLACES } from "./constants";
import { useTranslation } from "react-i18next";
import FlexContainer from "~/components/ui/FlexContainer";

export const SelectMarketPlace = ({
  onboardingChoices,
  setOnboardingChoices,
}: StepProps) => {
  const { t } = useTranslation();

  const newMarketplaces = MARKETPLACES
    ? [...MARKETPLACES, { name: t("onboarding.noStore"), value: "no_store" }]
    : [];

  const handleNoStoreSelection = () => {
    setOnboardingChoices((old) => {
      return {
        generation_preferences: old?.generation_preferences || [],
        marketplaces: old?.marketplaces?.includes("no_store")
          ? []
          : ["no_store"],
      };
    });
  };

  const handleMarketplaceSelection = (value: string) => {
    setOnboardingChoices((old) => {
      return {
        generation_preferences: old?.generation_preferences || [],
        marketplaces: old?.marketplaces?.includes(value)
          ? old?.marketplaces.filter((catValue) => catValue !== value)
          : [...(old?.marketplaces || []), value],
      };
    });
  };

  const handleMarketplaceSelect = (catValue: string) => {
    if (catValue === "no_store") {
      handleNoStoreSelection();
    } else {
      handleMarketplaceSelection(catValue);
    }
  };

  return (
    <FlexContainer gap="16px" flexWrap="wrap" width="100%">
      {newMarketplaces?.map((category) => (
        <S.CardPreference
          key={category.value}
          className={
            onboardingChoices?.marketplaces?.includes(category.value)
              ? "active"
              : ""
          }
          onClick={() => handleMarketplaceSelect(category.value)}>
          {category.name}
        </S.CardPreference>
      ))}
    </FlexContainer>
  );
};
