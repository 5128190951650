import * as S from "./plansModal.style";
import { SVG } from "~/components/ui/SVG";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import { Plans } from "~/components/plans";
import {
  SHOW_PLANS_MODAL,
  triggerShowResumeModal,
} from "~/helpers/customEvents";
import ReactDOM from "react-dom";
import { usePlanFeature } from "~/hooks/usePlanFeature";
import Button from "~/components/ui/Button";
import FlexContainer from "~/components/ui/FlexContainer";
import { useTranslation } from "react-i18next";

export const PlansModal = () => {
  const { t } = useTranslation();
  const [hideCloseButton, setHideCloseButton] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState<
    | { show: false; initialIsAnnual?: undefined }
    | { show: true; initialIsAnnual: boolean }
  >({
    show: false,
  });
  const { isFeaturePaused } = usePlanFeature();

  // Show plans modal when user needs to upgrade
  useEffect(() => {
    const handleRequiresUpgrade = (
      e: CustomEvent<{ initialIsAnnual: boolean; hideCloseButton: boolean }>
    ) => {
      if (isFeaturePaused()) {
        triggerShowResumeModal();
      } else {
        setHideCloseButton(e.detail.hideCloseButton);
        setShowPlansModal({
          show: true,
          initialIsAnnual: e?.detail?.initialIsAnnual,
        });
      }
    };
    // @ts-ignore
    window.addEventListener(SHOW_PLANS_MODAL, handleRequiresUpgrade);
    return () => {
      // @ts-ignore
      window.removeEventListener(SHOW_PLANS_MODAL, handleRequiresUpgrade);
    };
  }, [isFeaturePaused]);

  if (!showPlansModal.show) return null;

  return ReactDOM.createPortal(
    <S.ModalWrapper>
      <S.ModalContent>
        {!hideCloseButton ? (
          <S.CloseContainer onClick={() => setShowPlansModal({ show: false })}>
            <SVG icon={faClose} size="xl" />
          </S.CloseContainer>
        ) : null}
        <Plans
          closeModal={() => setShowPlansModal({ show: false })}
          initialIsAnnual={showPlansModal.initialIsAnnual}
        />
        {hideCloseButton ? (
          <FlexContainer
            alignItems="flex-end"
            justifyContent="flex-end"
            width="100%">
            <Button
              color="textSecondary"
              bgColor="transparent"
              onClick={() => setShowPlansModal({ show: false })}>
              {t("onboarding.back")}
            </Button>
          </FlexContainer>
        ) : null}
      </S.ModalContent>
    </S.ModalWrapper>,
    document.body
  );
};
